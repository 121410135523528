var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[(_vm.addUser)?_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.hasAuthorization},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":!_vm.hasAuthorization,"outlined":"","data-test":"add-btn"},on:{"click":function($event){return _vm.setShowDialog()}}},[_vm._v(" Add Member ")])],1)]}}],null,false,1122829104)},[_c('span',[_vm._v(" You don't have this kind of authorization. ")])]):_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.hasAuthorization},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-list-item-title',{attrs:{"data-test":"edit-list"}},[_vm._v(" Edit ")])],1),_c('span',_vm._g({},on),[_c('v-icon',_vm._g({attrs:{"disabled":!_vm.hasAuthorization,"left":"","data-test":"edit-icon"}},on),[_vm._v(" mdi-pencil ")])],1)]}}])},[_c('span',[_vm._v(" You don't have this kind of authorization. ")])]),_c('v-dialog',{attrs:{"max-width":"450"},on:{"click:outside":_vm.close},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{attrs:{"data-test":"namespaceNewMember-dialog"}},[_c('v-card-title',{staticClass:"headline grey lighten-2 text-center"},[_vm._v(" "+_vm._s(_vm.addUser ? 'Add member to namespace' : 'Update member role')+" ")]),_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-card-text',{staticClass:"caption mb-0"},[_c('ValidationProvider',{ref:"providerUsername",attrs:{"name":"Username","rules":"required","vid":"username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":!_vm.addUser,"label":"Username","error-messages":errors,"require":"","data-test":"username-text"},model:{value:(_vm.memberLocal.username),callback:function ($$v) {_vm.$set(_vm.memberLocal, "username", $$v)},expression:"memberLocal.username"}})]}}],null,true)})],1),_c('v-card-text',{staticClass:"caption mb-0 pt-0"},[_c('ValidationProvider',{ref:"providerRole",attrs:{"vid":"role","name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.items,"label":"Role","error-messages":errors,"require":"","data-test":"role-select"},model:{value:(_vm.memberLocal.selectedRole),callback:function ($$v) {_vm.$set(_vm.memberLocal, "selectedRole", $$v)},expression:"memberLocal.selectedRole"}})],1)],1)]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","data-test":"dialogClose-btn"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Close ")]),(_vm.addUser)?_c('v-btn',{attrs:{"color":"primary","text":"","data-test":"dialogAdd-btn"},on:{"click":function($event){return passes(_vm.addMember)}}},[_vm._v(" Add ")]):_c('v-btn',{attrs:{"color":"primary","text":"","data-test":"dialogEdit-btn"},on:{"click":function($event){return passes(_vm.editMember)}}},[_vm._v(" Edit ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }